import * as math from 'mathjs';
import { typeOrNull } from './data-helpers';

export const matrixCalculators = {
  'IEC_61853-1_matrix': field => {
    // the short-circuit current at reference conditions
    const referenceRow = field.rows.data.indexOf(1000);
    const referenceCol = field.cols.data.indexOf(25);
    const I_sc_0 = field.value.get([referenceRow, referenceCol, 0]);

    const VIFT = [];

    field.rows.data.forEach((row, i) =>
      field.cols.data.forEach((col, j) => {
        const override = field.overrides.find(
          override => override.cell.join() === [row, col].join()
        );

        if (override) {
          if (
            override.disabled &&
            override.disabled.length === field.inputs.length
          ) {
            return;
          }
        }

        const subset = field.value.subset(
          math.index(i, j, math.range(0, field.inputs.length))
        );
        const [I_sc, P_mp, V_mp, V_oc] = subset
          .toArray()[0][0]
          .map(typeOrNull(Number));

        // every value needs to exist and be positive
        if (![I_sc, P_mp, V_mp, V_oc].every(value => value > 0)) {
          return;
        }

        const F = I_sc / I_sc_0;
        const T = col;

        VIFT.push(
          [0, I_sc, F, T], // Isc
          [V_mp, P_mp / V_mp, F, T], // Pmp
          [V_oc, 0, F, T] // Voc
        );
      })
    );

    const data = math.matrix(VIFT);
    const getColumn = index =>
      data
        .subset(math.index(math.range(0, VIFT.length), index))
        .toArray()
        .map(val => val[0]);

    // map matrix to i_v curve data fields
    return ['V_V', 'I_A', 'F', 'T_degC'].map((name, k) => ({
      name,
      fieldset: 'i_v_curve_data',
      type: 'array',
      value: getColumn(k).join(),
    }));
  },
};

export const matrixValidators = {
  'IEC_61853-1_matrix': field => {
    let isValid = true;

    field.rows.data.forEach((row, i) =>
      field.cols.data.forEach((col, j) => {
        if (!isValid) return isValid;

        const override = field.overrides.find(
          override => override.cell.join() === [row, col].join()
        );

        if (override) {
          if (
            override.disabled &&
            override.disabled.length === field.inputs.length
          ) {
            return;
          }

          if (override.nullable) {
            if (override.nullable.length === field.inputs.length) {
              return;
            }
          }
        }

        const subset = field.value.subset(
          math.index(i, j, math.range(0, field.inputs.length))
        );
        const [I_sc, P_mp, V_mp, V_oc] = subset
          .toArray()[0][0]
          .map(typeOrNull(Number));

        // every non-nullable value needs to exist unless and be positive
        if (
          ![I_sc, P_mp, V_mp, V_oc]
            .filter(
              value =>
                !override ||
                !override.nullable ||
                !override.nullable.includes(Object.keys({ value })[0])
            )
            .every(value => value > 0)
        ) {
          isValid = false;
        }
      })
    );

    return isValid;
  },
};

import React from 'react';
import PropTypes from 'prop-types';
import Content from './content';

import '../styles/alert.scss';

export const AlertType = {
  DEBUG: 'debug',
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error',
  SUCCESS: 'success',
};

const Alert = ({ type, body }) => (
  <section className={`alert ${type}`}>
    <Content className="alert-body" source={body} />
  </section>
);

Alert.propTypes = {
  type: PropTypes.oneOf([
    AlertType.DEBUG,
    AlertType.INFO,
    AlertType.WARN,
    AlertType.ERROR,
    AlertType.SUCCESS,
  ]),
  body: PropTypes.string,
  position: PropTypes.string,
};

export default Alert;

export const ALERT_OFFLINE = {
  type: AlertType.WARN,
  body: `
### It looks like you're offline. 
_You'll need an internet connection to submit data._
`,
};

import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@rmwc/icon';

const Field = ({ tools, className, children }) => (
  <div className={`field-container ${className}`}>
    {children}
    <div className="field-tools">
      {tools &&
        tools
          .filter(_ => !!_)
          .map((tool, i) => (
            <Icon
              key={i}
              className="tool-icon"
              tabIndex="0"
              title={tool.title}
              icon={tool.icon}
              onClick={tool.onClick}
            />
          ))}
    </div>
  </div>
);

Field.propTypes = {
  tools: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
};

export default Field;

import React from 'react';
import { graphql, Link, withPrefix } from 'gatsby';
import { Router } from '@reach/router';

import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import Layout from '../components/layout';
import Form from '../components/form';
import DataProvider from '../components/data-provider';
import ApiExample from '../components/api';

const shortcodes = { Link };

const FitSection = props => (
  <section className="section">
    <Form
      {...props}
      alert={{ ...props.alert, position: 'Output' }}
    />
  </section>
);

const InfoSection = ({ body }) => (
  <section className="section">
    <MDXProvider components={shortcodes}>
      <MDXRenderer>{body}</MDXRenderer>
    </MDXProvider>
  </section>
);

const ApiSection = props => (
  <section className="section">
    <ApiExample {...props} />
  </section>
);

export const FormPageTemplate = ({ slug, form, examples, api, body }) => {
  return (
    <DataProvider
      form={form}
      examples={examples}
      render={({ status }, form, actions, alert) => (
        <Router basepath={withPrefix(slug)}>
          <InfoSection path="info" body={body} />
          <FitSection
            path="fit"
            form={form}
            alert={alert}
            actions={{ ...actions, ...status }}
          />
          <ApiSection path="api" form={form} api={api} example={examples[0]} />
        </Router>
      )}
    />
  );
};

const FormPage = ({ data: { page, form, exampleData, api } }) => {
  return (
    <Layout
      meta={page.frontmatter.meta || false}
      title={page.frontmatter.title || false}
      slug={page.frontmatter.slug || false}
      tabs={page.frontmatter.tabs || false}
    >
      <FormPageTemplate
        slug={page.frontmatter.slug || false}
        body={page.body}
        form={form}
        examples={exampleData ? exampleData.examples : null}
        api={api}
      />
    </Layout>
  );
};

export default FormPage;

export const pageQuery = graphql`
  query FormPage($id: String, $slug: String) {
    page: mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        slug
        template
        featuredImage
        meta {
          title
          description
        }
        tabs {
          path
          label
        }
      }
    }
    form: formsYaml(name: { eq: $slug }) {
      id
      name
      title
      dataProvider {
        method
        url
        contentType
      }
      sections {
        name
        label
        tabbed
        actions {
          name
          label
          type
          options
          position
          disable
        }
        fieldsets {
          name
          label
          tab
          icon
          actions {
            name
            label
            type
            position
            disable
          }
          fields {
            name
            type
            label
            editable
            required
            value
            options {
              label
              value
            }
            rows {
              name
              label
              sticky
              data
            }
            cols {
              name
              label
              sticky
              data
            }
            overrides {
              cell
              nullable
            }
            inputs {
              name
              label
              type
            }
          }
        }
      }
    }
    exampleData: examplesYaml(name: { eq: $slug }) {
      name
      examples {
        fieldset
        label
        data {
          name
          value
        }
      }
    }
    api: samplesYaml(name: { eq: $slug }) {
      name
      heading
      description
      platforms {
        name
        language
        ext
        description
        template
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@rmwc/icon';

import { useLocationState, getLocationValues } from '../actions/location-state';
import FieldSet from './fieldset';

const SectionTabs = ({ name, section, onTabChange }) => {
  const locationValues = getLocationValues();
  const storageValues = JSON.parse(window.localStorage.getItem(name));

  const activeFieldset = section.fieldsets.find(fieldset => !!fieldset.active);
  const initialActiveTab = activeFieldset ? activeFieldset.name : null;
  const initialTab = locationValues[section.name]
    ? locationValues[section.name]
    : storageValues && storageValues[section.name]
    ? storageValues[section.name]
    : section.fieldsets[0].name;

  const [activeTab, setActiveTab] = useLocationState(initialTab, section.name);

  const updateActiveFieldset = React.useCallback(
    activeTab => {
      const fieldset = section.fieldsets
        ? section.fieldsets.find(fieldset => fieldset.name === activeTab)
        : null;
      if (!fieldset) return;
      setActiveTab(fieldset.name);
      onTabChange(fieldset.name);
    },
    [section, setActiveTab, onTabChange]
  );

  React.useEffect(() => {
    if (activeTab !== initialActiveTab) {
      updateActiveFieldset(initialActiveTab ? initialActiveTab : activeTab);
    }

    return () => window.localStorage.removeItem(name);
  }, [name, activeTab, initialActiveTab, updateActiveFieldset]);

  return (
    <>
      <div className="fieldset-tabs">
        {section.fieldsets.map(fieldset => (
          <button
            key={fieldset.name}
            type="button"
            className={`fieldset-tab ${
              activeTab === fieldset.name ? 'active' : ''
            }`}
            onClick={() => updateActiveFieldset(fieldset.name)}
          >
            <Icon icon={fieldset.icon} />
            {fieldset.tab}
          </button>
        ))}
      </div>
    </>
  );
};

SectionTabs.propTypes = {
  section: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    fieldsets: PropTypes.arrayOf(PropTypes.shape(FieldSet.propTypes)),
  }),
  onTabChange: PropTypes.func,
};

export default SectionTabs;

import React from 'react';
import PropTypes from 'prop-types';

import * as math from 'mathjs';

import { TextField } from '@rmwc/textfield';

import '../styles/data-table.scss';
import '../styles/matrix.scss';

const isEditable = (overrides, key, field) =>
  !(
    overrides.has(key) &&
    Array.isArray(overrides.get(key).disabled) &&
    overrides.get(key).disabled.includes(field.name)
  );

const isNullable = (overrides, key, field) =>
  overrides.has(key) &&
  Array.isArray(overrides.get(key).nullable) &&
  overrides.get(key).nullable.includes(field.name);

const InputCell = ({ field, onFocus, onChange }) => (
  <TextField
    name={field.name}
    type={field.type}
    step={field.type === 'number' ? 'any' : null}
    min="0"
    label={field.label}
    className={field.nullable ? 'nullable' : ''}
    floatLabel={field.value != null || undefined}
    value={field.value != null ? field.value : ''}
    required={!field.nullable && !!field.requirable}
    disabled={field.editable === false && !field.value ? true : null}
    readOnly={field.editable === false ? true : null}
    onFocus={onFocus}
    onChange={e => {
      e.preventDefault();
      onChange({ ...field, value: e.target.value });
    }}
  />
);

const MultiInputCell = ({ fields, onFocus, onChange }) => (
  <div className="multi-input">
    {fields.map((field, i) => (
      <TextField
        key={i}
        name={field.name}
        type={field.type}
        step={field.type === 'number' ? 'any' : null}
        min="0"
        label={field.label}
        className={field.nullable ? 'nullable' : ''}
        floatLabel={field.value != null || undefined}
        value={field.value != null ? field.value : ''}
        required={!field.nullable && !!field.requirable}
        disabled={field.editable === false && !field.value ? true : null}
        readOnly={field.editable === false ? true : null}
        onFocus={onFocus}
        onChange={e => {
          e.preventDefault();
          onChange({ ...field, value: e.target.value, index: i });
        }}
      />
    ))}
  </div>
);

class Matrix extends React.Component {
  constructor(props) {
    super(props);

    const { rows, cols, overrides, inputs, value } = this.props;
    const matrix = math.matrix(
      rows.data.map((_row, i) =>
        cols.data.map((_col, j) =>
          inputs.map((_field, k) => value && value.get([i, j, k]))
        )
      )
    );

    this.overrides = new Map(
      overrides.map(override => {
        return [override.cell.join(), override];
      })
    );

    let nextRow = 0;
    let nextCol = 0;
    while (
      this.overrides.has([rows.data[nextRow], cols.data[nextCol]].join()) &&
      (this.overrides.get([rows.data[nextRow], cols.data[nextCol]].join())
        .disabled ||
        this.overrides.get([rows.data[nextRow], cols.data[nextCol]].join())
          .nullable)
    ) {
      nextCol++;
      if (nextCol >= cols.length) {
        nextCol = 0;
        nextRow++;
      }
    }

    this.state = {
      matrix,
      activeCell: [nextRow, nextCol],
    };
  }

  componentDidUpdate({ value }) {
    if (this.props.value && this.props.value !== value) {
      this.setState({ matrix: math.matrix(this.props.value) });
    }
  }

  handleChange(index, field) {
    this.setState(
      ({ matrix }) => {
        let valueToStore = field.value;
        if (valueToStore === '') {
          valueToStore = null;
        }
        matrix.set(index, valueToStore);
        return { matrix };
      },
      () => {
        this.props.onChange({
          value: this.state.matrix,
        });
      }
    );
  }

  render() {
    const { checkValidity, tableData, rows, cols, inputs } = this.props;
    return (
      <>
        <table
          className={`data-table ${tableData.sticky.col ? 'sticky col' : ''}`}
          style={tableData.style}
        >
          <thead>
            <tr className={tableData.sticky.row ? 'sticky row' : ''}>
              <th className="heading center" rowSpan="2">
                {rows.label}
              </th>
              <th colSpan={cols.data.length} style={{ padding: 0 }}>
                <table className="data-table">
                  <thead>
                    <tr>
                      <th className="heading center" colSpan={cols.data.length}>
                        {cols.label}
                      </th>
                    </tr>
                    <tr>
                      {cols.data.map((colHeading, i) => (
                        <th key={i} className="subheading center">
                          {colHeading}
                        </th>
                      ))}
                    </tr>
                  </thead>
                </table>
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.data.map((row, i) => (
              <tr key={i}>
                <th className="center">{row}</th>
                {cols.data.map((col, j) => (
                  <td
                    key={j}
                    className={`center ${
                      this.state.activeCell.join() === [i, j].join()
                        ? 'active'
                        : ''
                    }`}
                  >
                    {inputs.length > 1 ? (
                      <MultiInputCell
                        fields={inputs.map((field, k) => ({
                          ...field,
                          editable: isEditable(
                            this.overrides,
                            [row, col].join(),
                            field
                          ),
                          nullable: isNullable(
                            this.overrides,
                            [row, col].join(),
                            field
                          ),
                          requirable: checkValidity,
                          value: this.state.matrix.get([i, j, k]),
                        }))}
                        onFocus={() => this.setState({ activeCell: [i, j] })}
                        onChange={field =>
                          this.handleChange([i, j, field.index], field)
                        }
                      />
                    ) : (
                      <InputCell
                        field={{
                          ...inputs.fields[0],
                          editable: isEditable(
                            this.overrides,
                            [row, col].join(),
                            inputs.fields[0]
                          ),
                          nullable: isNullable(
                            this.overrides,
                            [row, col].join(),
                            inputs.fields[0]
                          ),
                          requirable: checkValidity,
                          value: this.state.matrix.get([i, j]),
                        }}
                        onFocus={() => this.setState({ activeCell: [i, j] })}
                        onChange={field => this.handleChange([i, j], field)}
                      />
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }
}

Matrix.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  checkValidity: PropTypes.bool,
  tableData: PropTypes.shape({
    sticky: PropTypes.shape({
      row: PropTypes.bool,
      col: PropTypes.bool,
    }),
    style: PropTypes.object,
  }),
  rows: PropTypes.shape({
    heading: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  }),
  cols: PropTypes.shape({
    heading: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  }),
  overrides: PropTypes.arrayOf(
    PropTypes.shape({
      cell: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ),
      disabled: PropTypes.arrayOf(PropTypes.string),
      nullable: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
    })
  ),
};

export default Matrix;

import React from 'react';
import PropTypes from 'prop-types';

import Prism from 'prismjs';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-python';

import Content from './content';
import FieldSet from './fieldset';
import Field from './field';

import {
  getPropertyByPath,
  getFieldByLookup,
  downloadData,
  templateHandlersReducer,
} from '../actions/data-helpers';

import 'prismjs/themes/prism.css';
import '../styles/api.scss';

class ApiExample extends React.Component {
  constructor(props) {
    super(props);
    this.props.api.platforms.forEach(platform => {
      platform.ref = React.createRef(null);
    });
  }

  state = {};

  componentDidMount() {
    Prism.highlightAll();
  }

  parseTemplate(platform) {
    return platform.template.replace(
      /{{([\w.|:,]+)}}/g,
      this.placeholderReplacer.bind(this)
    );
  }

  placeholderReplacer(match, p1) {
    const [propPath, ...pipeFns] = p1.split('|');
    const pathArr = propPath.split('.');
    const formProp = getPropertyByPath(this.props.form, pathArr);
    const formField = getFieldByLookup(this.props.form, ...pathArr);
    const example = this.props.example.data.find(
      field => formField && field.name === formField.name
    );
    const value = formProp || formField.value || example?.value;
    if (formField && value !== example?.value) {
      // don't strip values from user entered data
      pipeFns.splice(
        pipeFns.findIndex(fn => /^takeNth/.test(fn)),
        1
      );
    }
    if (value) {
      if (pipeFns) {
        return pipeFns.reduce(templateHandlersReducer, value);
      }
      return value;
    }
    return match;
  }

  handleCopy(platform) {
    platform.ref.current.select();
    this.setState({
      [platform.name]: {
        copied: document.execCommand('copy'),
      },
    });
    setTimeout(() => {
      this.setState({
        [platform.name]: {
          copied: false,
        },
      });
    }, 1200);
  }

  handleSave(platform) {
    const output = this.parseTemplate(platform);

    this.setState({
      [platform.name]: {
        saved: downloadData(
          output,
          [['pvfit', this.props.api.name].join('-'), platform.ext].join('.')
        ),
      },
    });
    setTimeout(() => {
      this.setState({
        [platform.name]: {
          saved: false,
        },
      });
    }, 1200);
  }

  render() {
    const { api } = this.props;

    return (
      <>
        <h2>{api.heading}</h2>
        <Content source={api.description} />
        {api.platforms.map(platform => {
          const template = this.parseTemplate(platform);
          return (
            <div key={platform.name} className="container">
              <Content source={platform.description} />

              <div
                className={`api-example ${
                  this.state[platform.name] &&
                  Object.keys(this.state[platform.name])
                    .filter(key => !!this.state[platform.name][key])
                    .join(' ')
                }`}
              >
                <Field
                  tools={[
                    {
                      icon: 'content_copy',
                      title: 'Copy',
                      onClick: () => this.handleCopy(platform),
                    },
                    {
                      icon: 'save_alt',
                      title: 'Save',
                      onClick: () => this.handleSave(platform),
                    },
                  ]}
                >
                  <pre>
                    <code className={`language-${platform.language}`}>
                      {template}
                    </code>
                  </pre>
                </Field>
                <textarea ref={platform.ref} defaultValue={template} />
              </div>
            </div>
          );
        })}
      </>
    );
  }
}

ApiExample.propTypes = {
  form: PropTypes.shape({
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        fieldsets: PropTypes.arrayOf(PropTypes.shape(FieldSet.propTypes)),
      })
    ),
  }),
  api: PropTypes.shape({
    name: PropTypes.string,
    platforms: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        template: PropTypes.string,
      })
    ),
  }),
};

export default ApiExample;

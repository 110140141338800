import React from 'react';
import PropTypes from 'prop-types';

import SectionTabs from './section-tabs';
import FieldSet from './fieldset';
import FormActions from './form-actions';
import Alert from '../components/alert';

import { initializeAction } from '../actions/data-helpers';

import '../styles/form.scss';

const Form = ({
  form: { title, name, dataProvider: config, sections },
  actions,
  alert,
}) => {
  // need local state to trigger rerender on tab change
  const [, setActiveTab] = React.useState(null);

  return (
    <>
      <h2>{title}</h2>
      <form
        name={name}
        method={config.method}
        action={config.url}
        className={Object.entries(actions)
          .filter(([_name, value]) => value === true)
          .map(([name, _value]) => name)
          .join(' ')}
      >
        {sections.length &&
          sections.map((section, i) => (
            <div key={i} className="container">
              <h3>
                {section.name}: {section.label}
              </h3>
              {section.actions && section.actions.length && (
                <FormActions
                  actions={section.actions
                    .filter(action => action.position === 'before')
                    .map(initializeAction(actions))}
                />
              )}

              {alert && alert.type && alert.position === section.name ? (
                <Alert {...alert} />
              ) : null}

              {section.tabbed && (
                <SectionTabs
                  name={name}
                  section={section}
                  onTabChange={activeTab => {
                    setActiveTab(activeTab);
                    actions.onTabChange(section.name, activeTab);
                  }}
                />
              )}

              {section.fieldsets.length &&
                section.fieldsets.map((fieldset, index) => {
                  return (
                    <div
                      key={index}
                      className="fieldset-container"
                      hidden={section.tabbed && !fieldset.active}
                    >
                      <FieldSet
                        key={index}
                        {...fieldset}
                        actions={
                          fieldset.actions &&
                          fieldset.actions.map(initializeAction(actions))
                        }
                        checkValidity={
                          !section.tabbed ||
                          (section.tabbed && !!fieldset.active)
                        }
                        onChange={actions.onChange}
                        onBlur={actions.onBlur}
                        onIconClick={{
                          close: actions.onClear,
                        }}
                      />
                    </div>
                  );
                })}
              {section.actions && section.actions.length && (
                <FormActions
                  actions={section.actions
                    .filter(
                      action => !action.position || action.position === 'after'
                    )
                    .map(initializeAction(actions))}
                />
              )}
            </div>
          ))}
      </form>
    </>
  );
};

Form.propTypes = {
  form: PropTypes.shape({
    title: PropTypes.string,
    name: PropTypes.string,
    dataProvider: PropTypes.object,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string,
        actions: FormActions,
        fieldsets: PropTypes.arrayOf(PropTypes.shape(FieldSet.propTypes)),
      })
    ),
  }),
  alert: PropTypes.shape({
    type: PropTypes.string,
    body: PropTypes.string,
    position: PropTypes.string,
  }),
  actions: PropTypes.shape({
    [PropTypes.string]: PropTypes.oneOf([PropTypes.func, PropTypes.bool]),
  }),
};

export default Form;

import React from 'react';

import {
  dataStringToArray,
  mapFormSectionsToFields,
  setPropertyByLookup,
  mapValuesToState,
  mapFormSectionsToState,
  mapDataToFormValues,
  getFieldsetByLookup,
  getFieldByLookup,
  findFieldByName,
  multiLineToMatrix,
} from '../actions/data-helpers';
import { AlertType, ALERT_OFFLINE } from './alert';

import {
  matrixCalculators,
  matrixValidators,
} from '../actions/data-calculators';

class DataProvider extends React.Component {
  constructor(props) {
    super(props);

    this.fields = this.props.form.sections.reduce(mapFormSectionsToFields, {});

    this.props.form.sections.forEach(section =>
      section.fieldsets.forEach(fieldset =>
        fieldset.fields.forEach(field => {
          field.fieldset = fieldset.name;
        })
      )
    );

    this.actions = {
      'load-example-data': this.loadExampleData.bind(this),
      'get-examples': this.getExamples.bind(this),
      'convert-to-ivft': this.onCalculate.bind(
        this,
        getFieldsetByLookup(this.props.form, 'IEC_61853-1_matrix')
      ),
      'compute-fit': this.handleRequest.bind(this),
      onChange: this.onChange.bind(this),
      onBlur: this.onBlur.bind(this),
      onClear: this.onClear.bind(this),
      onTabChange: this.onTabChange.bind(this),
    };

    this.alerts = {
      offline: ALERT_OFFLINE,
      error: { type: AlertType.ERROR, position: 'Output' },
      success: { type: AlertType.SUCCESS, position: 'Output' },
    };

    this.state = {
      status: {
        invalid: true,
        offline: null,
        loading: null,
        success: null,
        error: null,
      },
    };
  }

  componentDidMount() {
    this.setState({
      ...this.props.form.sections.reduce(mapFormSectionsToState, this.state),
    });
    this.tryConnection();
    this.checkValidity();
  }

  tryConnection = async () => {
    const { url } = this.props.form.dataProvider;
    let error;
    const response = await fetch(url, { method: 'OPTIONS' }).catch(err => {
      error = err;
    });
    const isOnline =
      !error && response && (response.ok || response.type === 'opaque');
    if (!isOnline) {
      window.addEventListener('online', this.tryConnection.bind(this));
      window.removeEventListener('offline', this.tryConnection.bind(this));
    } else {
      window.addEventListener('offline', this.tryConnection.bind(this));
      window.removeEventListener('online', this.tryConnection.bind(this));
    }

    this.setState({
      status: {
        ...this.state.status,
        offline: !isOnline || null,
      },
    });
  };

  onChange = field => {
    const { fieldset, name, type, value } = field;
    let valueToStore;
    let validity = true;
    switch (type) {
      case 'array':
        valueToStore = value === '' ? null : dataStringToArray(value);
        validity =
          !field.required ||
          (!!field.required &&
            Array.isArray(valueToStore) &&
            valueToStore.length &&
            valueToStore.every(val => val != null));
        break;
      case 'number':
        valueToStore = value === '' ? null : Number(value);
        validity =
          !field.required ||
          (!!field.required &&
            valueToStore != null &&
            !Number.isNaN(valueToStore));
        break;
      case 'matrix':
        valueToStore = value;
        validity = matrixValidators[name](field, value);
        break;
      default:
        valueToStore = value === '' ? null : value;
        validity =
          !field.required || (!!field.required && valueToStore != null);
        break;
    }
    setPropertyByLookup(this.props.form, fieldset, name, { value, validity });
    this.checkValidity();
    this.setState({
      [name]: { display: value, value: valueToStore, validity },
    });
  };

  onBlur = field =>
    field.type === 'array' &&
    field.value.length &&
    // handle formatting to csv for display in input
    this.onChange({ ...field, value: String(dataStringToArray(field.value)) });

  onClear = field => {
    this.onChange({ ...field, value: '' });
  };

  onCalculate = fieldset => {
    const values = [];
    // TODO: move fieldset/tab change target to config?
    fieldset.fields.forEach(field => {
      if (field.type === 'matrix') {
        if (typeof matrixCalculators[field.name] !== 'function') {
          return console.warn(`${field.name} not found in matrixCalculators`);
        }
        return values.push(...matrixCalculators[field.name](field));
      }
      values.push({ ...field, fieldset: 'i_v_curve_data' });
    });
    values.forEach(this.onChange.bind(this));
    this.onTabChange('Input', 'i_v_curve_data');
  };

  onTabChange = (sectionName, fieldsetName) => {
    const section = this.props.form.sections.find(
      section => section.name === sectionName
    );
    if (!section) return;
    section.fieldsets.forEach(fieldset => {
      const active = fieldset.name === fieldsetName;
      fieldset.active = active;
      this.fields[fieldset.name].active = active;
    });
    this.clearNonEditableFields().then(this.checkValidity.bind(this));
  };

  loadExampleData = event => {
    const { data } = event.detail;

    const handler = ([name, field]) => {
      if (!Object.keys(field).includes('value')) {
        return Object.entries(field).forEach(handler.bind(this));
      }

      const fieldData = data.find(value => value.name === name);
      if (!fieldData) return;

      let { value } = fieldData;

      if (field.type === 'array') {
        value = String(dataStringToArray(value));
      }
      if (field.type === 'matrix') {
        value = multiLineToMatrix(value);
      }

      this.onChange({ ...field, name, value });
    };
    return Object.entries(this.fields).forEach(handler.bind(this));
  };

  getExamples = () => {
    const { examples, form } = this.props;
    if (!examples || !form) return null;

    return examples.filter(example => {
      const fieldset = getFieldsetByLookup(form, example.fieldset);
      return fieldset.active !== false;
    });
  };

  checkValidity = () => {
    const handler = (isValid, [name, field]) => {
      if (!isValid) return isValid;
      if (!Object.keys(field).includes('value')) {
        return Object.entries(field).reduce(handler.bind(this), isValid);
      }
      if (!field.required && field.type !== 'matrix') return true;
      if (field.required && field.editable === false && field.value != null)
        return true;
      if (this.fields[field.fieldset].active === false) return true;
      const formField = getFieldByLookup(this.props.form, field.fieldset, name);
      return !!formField.validity;
    };
    const isValid = Object.entries(this.fields).reduce(
      handler.bind(this),
      true
    );
    this.setState({
      status: {
        ...this.state.status,
        invalid: !isValid,
      },
    });
  };

  sendRequest = async ({ url, method, body }) => {
    const response = await fetch(url, {
      method,
      body,
    });
    if (response.ok && response.status === 200) {
      const data = await response.json();

      if (data.errorMessage) {
        return this.handleError({ message: data.errorMessage });
      }

      this.props.form.sections.forEach(mapDataToFormValues(data));

      const status = Object.assign(this.state.status, {
        loading: false,
        success: data.success || null,
        error: !data.success || null,
      });
      return this.setState({
        status,
        ...mapValuesToState(this.props.form, data),
      });
    }
    return this.handleError(response);
  };

  clearNonEditableFields = () => {
    return Promise.all(
      this.props.form.sections.map(section =>
        section.fieldsets.map(fieldset =>
          fieldset.fields.map(field => {
            if (field.editable === false && !field.required) {
              setPropertyByLookup(this.props.form, field.fieldset, field.name, {
                value: '',
              });
              return this.setState(
                {
                  status: {
                    ...this.state.status,
                    success: null,
                    error: null,
                  },          
                  [field.name]: { display: '', value: '' },
                },
                () => Promise.resolve()
              );
            } else {
              return Promise.resolve();
            }
          })
        )
      )
    );
  };

  handleRequest = async () => {
    await this.clearNonEditableFields();

    const { method, url, contentType } = this.props.form.dataProvider;
    const getFieldsetByField = name => {
      const field = findFieldByName(this.fields, name, true);
      if (!field) return null;
      return getFieldsetByLookup(this.props.form, field.fieldset, true);
    };
    const values = Object.entries(this.state)
      .filter(
        ([name, prop]) =>
          !!prop &&
          prop.value != null &&
          prop.value !== '' &&
          prop.value !== false &&
          getFieldsetByField(name) &&
          getFieldsetByField(name).active !== false
      )
      .reduce((set, [name, prop]) => {
        const field = findFieldByName(this.fields, name, true);
        if (field.type === 'matrix') {
          if (typeof matrixCalculators[name] !== 'function') {
            console.warn(`${name} not found in matrixCalculators`);
            return set;
          }
          const fields = matrixCalculators[name]({
            ...field,
            value: prop.value,
          });
          fields.forEach(field => {
            switch (field.type) {
              case 'array':
                set[field.name] = dataStringToArray(field.value);
                break;
              default:
                set[field.name] = field.value;
                break;
            }
          });
          return set;
        }
        set[name] = prop.value;
        return set;
      }, {});
    let body;

    switch (contentType) {
      case 'application/json':
        body = JSON.stringify(values);
        break;
      default:
        break;
    }

    return this.setState(
      {
        status: {
          ...this.state.status,
          loading: true,
          success: null,
          error: null,
        },
      },
      this.sendRequest.bind(this, { url, method, body })
    );
  };

  handleError = error => {
    this.setState({
      status: { loading: false, success: false, error },
      response: null,
    });
  };

  setAlert() {
    const { status, message } = this.state;
    let alert = null;
    Object.keys(this.alerts).forEach(state => {
      if (status[state] && !alert) {
        alert = this.alerts[state];
        if(!alert.body) {
          if (status[state].message) {
            alert.body = status[state].message;
          }
          if(message.display) {
            alert.body = message.display;
          }
        }
      }
    });
    return alert;
  }

  render() {
    return this.props.render(
      this.state,
      this.props.form,
      this.actions,
      this.setAlert()
    );
  }
}

export default DataProvider;

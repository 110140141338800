import React from 'react';
import PropTypes from 'prop-types';

import Field from './field';
import Matrix from './matrix';
import FormActions from './form-actions';

import { TextField } from '@rmwc/textfield';
import { Select } from '@rmwc/select';

const handleCopy = field => {
  const { current } = field.ref;
  current.select();
  field.copied = document.execCommand('copy');
  current.blur();
  return setTimeout(() => {
    field.copied = false;
  }, 1200);
};

const FieldSet = ({
  label,
  actions,
  fields,
  checkValidity,
  onChange,
  onBlur,
  onIconClick = {},
}) => {
  fields.forEach(field => {
    field.ref = React.createRef(null);
  });

  return (
    <>
      <legend>{label}</legend>
      {fields.map((field, i) => {
        const closeIconConfig =
          field.editable !== false && field.value
            ? {
                icon: 'close',
                title: 'Clear',
                onClick: () => onIconClick.close(field),
              }
            : null;

        const copyIconConfig = field.value
          ? {
              icon: 'content_copy',
              title: 'Copy',
              onClick: () => handleCopy(field),
            }
          : null;

        switch (field.type) {
          case 'select':
            return (
              <Field key={i} tools={[closeIconConfig]}>
                <Select
                  enhanced
                  name={field.name}
                  label={field.label}
                  value={field.value}
                  options={field.options}
                  required={!!checkValidity && !!field.required}
                  onChange={e => onChange({ ...field, value: e.target.value })}
                />
              </Field>
            );
          case 'array':
          case 'textarea':
            return (
              <Field
                key={i}
                tools={[closeIconConfig, copyIconConfig]}
                className={field.copied ? 'copied' : ''}
              >
                <TextField
                  textarea
                  outlined
                  key={i}
                  name={field.name}
                  type={field.type}
                  label={field.label}
                  value={field.value != null ? field.value : ''}
                  rows={4}
                  required={!!checkValidity && !!field.required}
                  disabled={
                    field.editable === false && field.value == null
                      ? true
                      : null
                  }
                  readOnly={field.editable === false ? true : null}
                  inputRef={field.ref}
                  onChange={e => onChange({ ...field, value: e.target.value })}
                  onBlur={e => onBlur({ ...field, value: e.target.value })}
                />
              </Field>
            );
          case 'matrix':
            return (
              <Matrix
                key={i}
                name={field.name}
                label={field.label}
                value={field.value}
                rows={field.rows}
                cols={field.cols}
                overrides={field.overrides}
                inputs={field.inputs}
                checkValidity={!!checkValidity}
                validity={!!field.validity}
                tableData={{
                  sticky: {
                    row: field.rows.sticky,
                    col: field.cols.sticky,
                  },
                  style: { maxHeight: '95vh' },
                }}
                onChange={e => onChange({ ...field, value: e.value })}
              />
            );
          default:
            if (field.type === 'datetime-local' && field.value) {
              field.value = field.value.replace(/\.\d{6}Z$/, '');
            }
            return (
              <Field
                key={i}
                tools={[closeIconConfig, copyIconConfig]}
                className={field.copied ? 'copied' : ''}
              >
                <TextField
                  key={i}
                  name={field.name}
                  type={field.type}
                  step={field.type === 'number' ? 'any' : null}
                  label={field.label}
                  value={field.value != null ? field.value : ''}
                  required={!!checkValidity && !!field.required}
                  disabled={
                    field.editable === false && field.value == null
                      ? true
                      : null
                  }
                  readOnly={field.editable === false ? true : null}
                  inputRef={field.ref}
                  onChange={e => onChange({ ...field, value: e.target.value })}
                />
              </Field>
            );
        }
      })}
      {actions && actions.length && <FormActions actions={actions} />}
    </>
  );
};

FieldSet.propTypes = {
  label: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.shape(FormActions.propTypes)),
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.oneOf([
        'text',
        'number',
        'datetime-local',
        'array',
        'textarea',
        'select',
        'matrix',
      ]),
      fieldset: PropTypes.string,
      value: PropTypes.any,
      required: PropTypes.bool,
      editable: PropTypes.bool,
      example: PropTypes.string,
      buttonLabel: PropTypes.string,
    })
  ),
  checkValidity: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onIconClick: PropTypes.shape({
    [PropTypes.string]: PropTypes.func,
  }),
};

export default FieldSet;

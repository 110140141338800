import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@rmwc/button';
import { LinearProgress } from '@rmwc/linear-progress';
import { Radio } from '@rmwc/radio';

const FormActions = ({ actions }) => {
  const [activeMenuIndex, setActiveMenuIndex] = React.useState(0);
  const getMenuItem = index => {
    const action = actions.find(action => action.type === 'menu');
    if (!action) return null;
    if (index >= action.menu.length) return action.menu[0];
    return action.menu[index];
  };

  return actions.length ? (
    <div className="form-actions">
      {actions.map((action, i) => {
        switch (action.type) {
          case 'button':
            return (
              <Button
                type="button"
                key={i}
                raised
                onClick={action.handler}
                disabled={action.disabled}
              >
                {action.label}
              </Button>
            );
          case 'button-secondary':
            return (
              <Button
                type="button"
                key={i}
                outlined
                className="button-secondary"
                onClick={event => {
                  event.preventDefault();
                  action.handler({
                    ...event,
                    detail: getMenuItem(activeMenuIndex),
                  });
                }}
                disabled={action.disabled}
              >
                {action.label}
              </Button>
            );
          case 'menu':
            return (
              <div key={i} className="menu-options">
                {action.menu &&
                  action.menu.length &&
                  action.menu.length > 1 &&
                  action.menu.map((item, i) => (
                    <Radio
                      key={i}
                      {...item}
                      checked={activeMenuIndex === i}
                      onChange={_event => setActiveMenuIndex(i)}
                    />
                  ))}
              </div>
            );
          case 'progress':
            return <LinearProgress key={i} closed={!action.handler} />;
          default:
            return null;
        }
      })}
    </div>
  ) : null;
};

FormActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.oneOf(['button', 'button-secondary', 'menu', 'progress']),
      disabled: PropTypes.bool,
      handler: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    })
  ),
};

export default FormActions;

import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

const Content = ({ source = '', className = '' }) => (
  <ReactMarkdown className={`content ${className}`} source={source} />
);

Content.propTypes = {
  source: PropTypes.string,
  className: PropTypes.string,
};

export default Content;

import * as math from 'mathjs';

export const isObject = value =>
  Object.prototype.toString.call(value) === '[object Object]';

export const getPropertyByPath = (src, path, nullValue = '') =>
  path.reduce(
    (data, part) => (data && data[part] != null ? data[part] : nullValue),
    src
  );

export const getFieldsetByLookup = (src, fieldsetName, activeOnly = false) => {
  const section = src.sections.find(section =>
    section.fieldsets.some(
      sectionFieldset => sectionFieldset.name === fieldsetName
    )
  );
  if (!section) return null;
  return section.fieldsets
    .filter(sectionFieldset =>
      activeOnly ? sectionFieldset.active !== false : true
    )
    .find(sectionFieldset => sectionFieldset.name === fieldsetName);
};

export const getFieldByLookup = (src, fieldsetName, fieldName) => {
  const sectionFieldset = getFieldsetByLookup(src, fieldsetName);
  if (!sectionFieldset) return null;
  return sectionFieldset.fields.find(field => field.name === fieldName);
};

export const findFieldByName = (src, fieldName, activeOnly = false) => {
  let found = null;
  for (const fieldset in src) {
    if (activeOnly && src[fieldset].active === false) {
      continue;
    }
    for (const name in src[fieldset]) {
      if (name === fieldName) {
        found = src[fieldset][name];
        break;
      }
    }
    if (found) break;
  }
  return found;
};

export const setPropertyByLookup = (src, fieldsetName, fieldName, values) => {
  const section = src.sections.find(section =>
    section.fieldsets.some(
      sectionFieldset => sectionFieldset.name === fieldsetName
    )
  );
  if (!section) return null;
  const sectionFieldset = section.fieldsets.find(
    sectionFieldset => sectionFieldset.name === fieldsetName
  );
  if (!sectionFieldset) return null;
  const field = sectionFieldset.fields.find(field => field.name === fieldName);
  if (!field) return null;
  Object.assign(field, values);
};

const nullMap = new Map([
  ['null', null],
  ['undefined', undefined],
  ['NaN', NaN],
]);

export const typeOrNull = targetType => value =>
  nullMap.has(value) ? nullMap.get(value) : targetType(value);

export const multiLineToMatrix = dataString =>
  math.matrix(
    dataString
      .split(/\n\n/)
      .map(row => row.split(/\n/).map(col => dataStringToArray(col)))
  );

export const dataStringToArray = (dataString, targetType = Number) =>
  // Trim any whitespace at ends first and remove and repeating commas
  dataString
    .trim()
    .replace(/\s+/g, ',')
    .replace(/,+/g, ',')
    .split(',')
    .map(typeOrNull(targetType));

export const mapFormSectionsToFields = (fields, item) => {
  if (item.fieldsets) {
    return item.fieldsets.reduce(mapFormSectionsToFields, fields);
  }
  if (item.fields) {
    item.fields.reduce((fields, field) => {
      fields[item.name] = fields[item.name] || {};
      fields[item.name][field.name] = {
        type: field.type,
        fieldset: item.name,
        required: !!field.required,
        editable: field.editable !== false,
        value: field.value || '',
      };
      if (field.type === 'matrix') {
        Object.assign(fields[item.name][field.name], {
          rows: field.rows,
          cols: field.cols,
          inputs: field.inputs,
          overrides: field.overrides,
        });
      }
      return fields;
    }, fields);
  }
  return fields;
};

export const mapFormSectionsToState = (state, item) => {
  if (item.fieldsets) {
    return item.fieldsets.reduce(mapFormSectionsToState, state);
  }
  if (item.fields) {
    item.fields.forEach(field => {
      // initialize with empty value, unless pre-populated
      const value = field.value || '';
      state[field.name] = { display: String(value), value };
    });
  }
  return state;
};

export const mapValuesToState = (form, values) => {
  const state = {};
  const reducer = (state, [fieldset, field]) => {
    if (isObject(field)) {
      Object.entries(field).forEach(([name, value]) => {
        // check form > section > fieldset > path match
        const formField = getFieldByLookup(form, fieldset, name);
        if (formField && value != null) {
          state[name] = { display: String(value), value: value };
        }
      });
    }
    return state;
  };
  return Object.entries(values).reduce(reducer, state);
};

export const mapDataToFormValues = data => item => {
  if (item.fieldsets) {
    return item.fieldsets.forEach(mapDataToFormValues(data));
  }
  if (item.fields) {
    item.fields.forEach(field => {
      field.value = getPropertyByPath(
        data,
        [field.fieldset, field.name],
        field.value
      );
    });
  }
};

export const createDataBlob = data => {
  return new Blob([data], { type: 'text/plain;charset=utf8;' });
};

export const downloadData = (data, filename) => {
  const link = document.createElement('a');

  link.href = URL.createObjectURL(createDataBlob(data));

  link.setAttribute('visibility', 'hidden');
  link.download = filename.replace(/ /g, '_');

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  return true;
};

export const templateHandlers = {
  compose: (...functions) => args =>
    functions.reduce((arg, fn) => fn(arg), args),
  takeNthOfArray: nth => arr => arr.filter((item, i) => (i + 1) % nth === 0),
};

export const templateHandlersReducer = (value, func) => {
  const [fn, ...args] = func.split(':');
  if (typeof templateHandlers[fn] === 'function') {
    // handle curried fn
    if (typeof templateHandlers[fn](...args) === 'function') {
      return templateHandlers.compose(templateHandlers[fn](...args))(value);
    }
    // user fn, value as this
    return templateHandlers[fn].apply(value, args);
  }
  // else built-in
  return value[fn](...args);
};

const disabledHandler = (checks, lookup) => {
  const pattern = /&|\|/;
  const [operator] = pattern.exec(checks) || [];
  switch (operator) {
    case '|':
      return checks.split(pattern).some(check => !!lookup[check]);
    case '&':
      return checks.split(pattern).every(check => !!lookup[check]);
    default:
      return lookup[checks];
  }
};

export const initializeAction = actions => action => {
  action.handler = actions[action.name];
  action.disabled = disabledHandler(action.disable, actions);
  if (action.type === 'menu') {
    action.menu =
      typeof actions[action.options] === 'function'
        ? actions[action.options]()
        : Array.isArray(actions.options)
        ? action.options
        : actions[action.options] || null;
  }
  return action;
};
